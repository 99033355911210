import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MainProducts from "../../components/MainProducts/MainProducts";
import Cards from "../../components/Cards";
import Disqus from "../../components/Disqus/Disqus";

const Home = () => {


    return (
        <React.Fragment>
            <Header />
            <Cards /> 
            <Footer />
        </React.Fragment>
    );
}

export default Home;
