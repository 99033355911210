import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { firestore, auth } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";

const ItemDetails = () => {
  const [itemId, setItemId] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [user, setUser] = useState(null);

  const dealTitleRef = useRef();
  const dealDescriptionRef = useRef();
  const dealListPriceRef = useRef();
  const dealSalePriceRef = useRef();
  const dealImageURL = useRef();
  const dealAffiliateURL = useRef();
  const dealInStock = useRef();
  const sellerPlatform = useRef();
  const ref = collection(firestore, "Deals");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        // console.log("User Info" + JSON.stringify(user));
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemDetails = async () => {
    try {
      const response = await axios.get(`https://ebay-node.vercel.app/api/singleitem/item/v1|${itemId}|0`);
      setItemDetails(response.data);
      setError(null);
      console.log(response.data);
    } catch (error) {
      setError('An error occurred while fetching the item data');
      setItemDetails(null);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + '-' + 
                      (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                      currentDate.getDate().toString().padStart(2, '0');

    const formattedTime = currentDate.getHours().toString().padStart(2, '0') + ':' + 
                      currentDate.getMinutes().toString().padStart(2, '0') + ':' + 
                      currentDate.getSeconds().toString().padStart(2, '0');
    
    const dateTimeStamp = formattedDate + ' ' + formattedTime;

    if (user?.email !== 'daviddoan01@gmail.com') {
      setError('You do not have permission to update this data.');
      return;
    }

    let data = {
        Title: dealTitleRef.current.value,
        Description: dealDescriptionRef.current.value,
        ListPrice: dealListPriceRef.current.value,
        SalePrice: dealSalePriceRef.current.value,
        ImageURL: dealImageURL.current.value,
        AffiliateURL: dealAffiliateURL.current.value,
        InStock: dealInStock.current.value,
        SellerPlatform: sellerPlatform.current.value,
        DateAdded: dateTimeStamp,
        DateUpdated: dateTimeStamp,
        SubmittedBy: 'ADMIN',
    }

    try {
        await addDoc(ref, data);
        setIsSaved(true);
    } catch (e) {
        console.log(e);
    }
  };

  if (!user) {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <button className="btn btn-primary" onClick={signInWithGoogle}>Sign in with Google</button>
          </div>
        </div>
      </div>
    );
  }

  if (user.email !== 'daviddoan01@gmail.com') {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <p className="text-danger">You do not have permission to view this page.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-12">
          <h1 className="mb-4">eBay Item Details</h1>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              value={itemId}
              onChange={(e) => setItemId(e.target.value)}
              placeholder="Enter Item ID"
            />
          </div>
          <button className="btn btn-primary mb-3" onClick={fetchItemDetails}>Fetch Item Details</button>
          {error && <p className="text-danger">{error}</p>}
          {itemDetails && (
            <div className="card mb-3">
              <div className="card-body">
                <h2 className="card-title">{itemDetails.title}</h2>
                <p className="card-text">${itemDetails?.price?.value}</p>
                <img src={itemDetails?.image?.imageUrl} alt={itemDetails.title} className="img-fluid mb-3" />
                <a href={itemDetails?.itemAffiliateWebUrl} className="btn btn-link">View on eBay</a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          {isSaved ? (
            <div className="alert alert-success" role="alert">
              Details saved successfully!
            </div>
          ) : (
            <form onSubmit={handleSave}>
              <div className="mb-3">
                <label className="form-label">Save the details to database</label>
                <input 
                  type="text" 
                  className="form-control" 
                  ref={dealTitleRef} 
                  value={itemDetails?.title || ''} 
                  onChange={(e) => setItemDetails({ ...itemDetails, title: e.target.value })} 
                  placeholder='Title' 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  ref={dealDescriptionRef} 
                  value={''} 
                  onChange={(e) => setItemDetails({ ...itemDetails, description: e.target.value })} 
                  placeholder='Description' 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="number" 
                  className="form-control" 
                  step="0.01" 
                  min="0" 
                  ref={dealListPriceRef} 
                  placeholder="List Price" 
                  onChange={(e) => setItemDetails({ ...itemDetails, listPrice: e.target.value })} 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="number" 
                  className="form-control" 
                  step="0.01" 
                  min="0" 
                  value={itemDetails?.price?.value || ''} 
                  ref={dealSalePriceRef} 
                  placeholder="Sale Price" 
                  onChange={(e) => setItemDetails({ ...itemDetails, price: { ...itemDetails.price, value: e.target.value } })} 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="url" 
                  className="form-control" 
                  id="image-url" 
                  name="image-url" 
                  value={itemDetails?.image?.imageUrl || ''} 
                  placeholder="Image URL" 
                  ref={dealImageURL} 
                  onChange={(e) => setItemDetails({ ...itemDetails, image: { ...itemDetails.image, imageUrl: e.target.value } })} 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="url" 
                  className="form-control" 
                  id="affiliate-url" 
                  name="affiliate-url" 
                  value={itemDetails?.itemAffiliateWebUrl || ''} 
                  placeholder="Affiliate URL" 
                  ref={dealAffiliateURL} 
                  onChange={(e) => setItemDetails({ ...itemDetails, itemAffiliateWebUrl: e.target.value })} 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  id="in-stock" 
                  name="in-stock" 
                  value={itemDetails?.estimatedAvailabilities[0]?.estimatedAvailabilityStatus || ''} 
                  placeholder="In Stock" 
                  ref={dealInStock}
                  onChange={(e) => setItemDetails({ ...itemDetails, estimatedAvailabilityStatus: e.target.value })} 
                />
              </div>
              <div className="mb-3">
                <input 
                  type="text" 
                  className="form-control" 
                  id="seller-platform" 
                  name="seller-platform" 
                  value="eBay" 
                  placeholder="Seller Platform" 
                  ref={sellerPlatform}
                  onChange={(e) => setItemDetails({ ...itemDetails, sellerPlatform: e.target.value })} 
                />
              </div>
              <button type="submit" className="btn btn-success">Save</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
