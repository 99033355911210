import React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import SingleItem from "../../../components/SingleItem";
import FireBaseExample from "../../../components/FireBaseExample/FireBase";

const AdminAdd = () => {
    return (
        <React.Fragment>
            <Header />
            <SingleItem />
            <Footer />
        </React.Fragment>
    );
}

export default AdminAdd;
