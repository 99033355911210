import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { firestore, auth } from "../../firebase";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { UpdateMainDiv } from "../SingleItemUpdate/styledComponents";

const ItemUpdate = () => {
  const [itemId, setItemId] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [items, setItems] = useState([]);
  const [editableItem, setEditableItem] = useState(null);

  const dealTitleRef = useRef();
  const dealDescriptionRef = useRef();
  const dealListPriceRef = useRef();
  const dealSalePriceRef = useRef();
  const dealImageURL = useRef();
  const dealAffiliateURL = useRef();
  const dealInStock = useRef();
  const ref = collection(firestore, "Deals");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        fetchItems();
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemDetails = async () => {
    try {
      const response = await axios.get(`https://ebay-node.vercel.app/api/singleitem/item/v1|${itemId}|0`);
      setItemDetails(response.data);
      setError(null);
      console.log(response.data);
    } catch (error) {
      setError('An error occurred while fetching the item data');
      setItemDetails(null);
    }
  };

  const fetchItems = async () => {
    try {
      const querySnapshot = await getDocs(ref);
      const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (item) => {
    setEditableItem(item);
  };

  const handleUpdate = async (id) => {

    const currentDate = new Date();
    const formattedDate = currentDate.getFullYear() + '-' + 
                      (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                      currentDate.getDate().toString().padStart(2, '0');

    const formattedTime = currentDate.getHours().toString().padStart(2, '0') + ':' + 
                      currentDate.getMinutes().toString().padStart(2, '0') + ':' + 
                      currentDate.getSeconds().toString().padStart(2, '0');
    
    const dateTimeStamp = formattedDate + ' ' + formattedTime;

    if (!window.confirm("Are you sure you want to update this item?")) {
      return;
    }

    const itemRef = doc(firestore, "Deals", id);
    let data = {
        Title: editableItem.Title,
        Description: editableItem.Description,
        ListPrice: editableItem.ListPrice,
        SalePrice: editableItem.SalePrice,
        ImageURL: editableItem.ImageURL,
        AffiliateURL: editableItem.AffiliateURL,
        InStock: editableItem.InStock,
        DateUpdated: dateTimeStamp,
        SellerPlatform: editableItem.SellerPlatform,
    }

    try {
        await updateDoc(itemRef, data);
        fetchItems(); // Refresh the items list
        setEditableItem(null); // Clear the editable item
    } catch (e) {
        console.log(e);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableItem({ ...editableItem, [name]: value });
  };

  if (!user) {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <button className="btn btn-primary" onClick={signInWithGoogle}>Sign in with Google</button>
          </div>
        </div>
      </div>
    );
  }

  if (user.email !== 'daviddoan01@gmail.com') {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <p className="text-danger">You do not have permission to view this page.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="row mt-5">
        <div className="col-12">
          <h2>All Items</h2>
          {items.map(item => (
            <div key={item.id} className="card mb-3">
              <div className="card-body">
                {editableItem && editableItem.id === item.id ? (
                  <>
                    <h2><u>Edit Item {editableItem.Title}</u></h2>

                    <div>
                    <img src={item.ImageURL} alt={item.Title} width="300" className="img-fluid mb-3" />
                    </div>

                    <div>
                      <label> Title:  </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="Title"
                        value={editableItem.Title}
                        onChange={handleChange}
                      />
                    </div>

                    
                    <div>
                      <label> Description:  </label>
                      <input
                        type="text"
                        className="form-control mb-2"
                        name="Description"
                        value={editableItem.Description}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label> List Price:  </label>
                      <input
                        type="number"
                        className="form-control mb-2"
                        name="ListPrice"
                        value={editableItem.ListPrice}
                        onChange={handleChange}
                      />
                     
                    </div>

                    <div>
                      <label> Sale Price:  </label>
                      <input
                        type="number"
                        className="form-control mb-2"
                        name="SalePrice"
                        value={editableItem.SalePrice}
                        onChange={handleChange}
                      />
                     
                    </div>

                    <div>
                      <label> Image URL:  </label>
                      <input
                        type="url"
                        className="form-control mb-2"
                        name="ImageURL"
                        value={editableItem.ImageURL}
                        onChange={handleChange}
                      />
                     
                    </div>

                    <div>
                      <label> Affiliate URL: </label>
                      <input
                        type="url"
                        className="form-control mb-2"
                        name="AffiliateURL"
                        value={editableItem.AffiliateURL}
                        onChange={handleChange}
                      />
                     
                    </div>

                    <div>
                      <label> In Stock: </label>
                      <select name="InStock" id="InStock" value={editableItem.InStock} onChange={handleChange}>
                        <option value="OUT_OF_STOCK">OUT_OF_STOCK</option>
                        <option value="IN_STOCK">IN_STOCK</option>
                      </select>
                    </div>

                    <div>
                      <label> Seller Platform: </label>
                      <select name="SellerPlatform" id="SellerPlatform" value={editableItem.SellerPlatform} onChange={handleChange}>
                        <option value="eBay">eBay</option>
                      </select>
                    </div>

                    <div>
                      <button className="btn btn-success" onClick={() => handleUpdate(item.id)}>Save</button>
                    </div>
                  </>
                ) : (
                  <>
                    <UpdateMainDiv>
                      <h3 className="card-title">{item.Title}</h3>
                      <span className="card-text">Description: {item.Description}</span>
                      <p className="card-text">List Price: ${item.ListPrice}</p>
                      <p className="card-text">Sale Price: ${item.SalePrice}</p>
                      <img src={item.ImageURL} alt={item.Title} width="300" className="img-fluid mb-3" />
                      <a href={item.AffiliateURL} className="btn btn-link">Affiliate Link</a>
                      <p className="card-text">In Stock: {item.InStock}</p>
                      <div>Seller Platform: {item.SellerPlatform}</div>
                      <button className="btn btn-primary" onClick={() => handleEdit(item)}>Edit</button>
                    </UpdateMainDiv>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ItemUpdate;
