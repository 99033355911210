import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const AdminMain = () => {
    return (
        <React.Fragment>
            <Header />
            <div className="container mt-5">
              <div className="row">
                <div className="col">
                  <a href="/admin/add" className="btn btn-primary" role="button">Add A Deal</a>
                </div>
                <div className="col">
                  <a href="/admin/update" className="btn btn-primary" role="button">Update A Deal</a>
                </div>
                <div className="col">
                  <a href="/admin/addcolumn" className="btn btn-primary" role="button">Add A Column For All Documents</a>
                </div>
               
                <div className="row mt-5">
                  <div className="col">
                    <a href="/postdealsadmin" className="btn btn-primary" role="button">Posted Deals</a>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
        </React.Fragment>
    );
}

export default AdminMain;
