import React, { useState, useRef, useEffect } from 'react';
import { firestore, auth } from "../../firebase";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { UpdateMainDiv } from "../SingleItemUpdate/styledComponents";
import { Link } from 'react-router-dom';
import { DiscussionEmbed, CommentCount } from 'disqus-react';

const Cards = () => {
  const [itemId, setItemId] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);

  const dealTitleRef = useRef();
  const dealListPriceRef = useRef();
  const dealSalePriceRef = useRef();
  const dealImageURL = useRef();
  const dealAffiliateURL = useRef();
  const dealInStock = useRef();
  const ref = collection(firestore, "Deals");
  const queryData = query(ref, where("InStock", "==", "IN_STOCK"));

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const querySnapshot = await getDocs(queryData);
      const itemsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setItems(itemsList);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row mt-5">
        <h2 id="deals">Deals</h2>
        <div className="col-12">
          <div className="row">
            {items.map(item => (
              <div key={item.id} className="col-md-4 mb-3">
                {/* <a href={item.AffiliateURL} className="card h-100 text-decoration-none text-dark shadow"> */}
                <Link to={`/deal/${item.id}`} className="card h-100 text-decoration-none text-dark shadow">
                  <div className="card-body">
                    <div className="text-secondary"><i className="fa fa-user"></i> found by {item.SubmittedBy}</div>
                    <img 
                      src={item.ImageURL} 
                      alt={item.Title} 
                      className="img-fluid mb-3" 
                      style={{ width: '300px', height: '300px', objectFit: 'cover' }} 
                    />
                    <h6 className="card-title">{item.Title}</h6>
                    {item.ListPrice ? (
                      <p className="card-text"><s>List Price: ${item.ListPrice}</s></p>
                    ) : (
                      <></>
                    )}
                    
                    <p className="card-text"><b>Sale Price: ${item.SalePrice}</b></p>
                    {/* <p className="card-text">In Stock: {item.InStock}</p> */}
                    {/* <p className="card-text">ID: {item.id}</p> */}
                    {/* Add Disqus Comment Count */}
                    <i className="bi bi-chat-right-dots">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-dots" viewBox="0 0 16 16">
                      <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                    </svg>&nbsp;
                    </i>
                    <CommentCount
                      shortname="pricelava" // Replace with your Disqus shortname
                      config={{ url: `${window.location.href}deal/${item.id}`, identifier: item.id, title: item.Title }}
                    >
                      Comments                      
                    </CommentCount>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
