import React, { useState, useEffect } from 'react';
import { firestore } from '../../../firebase';
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AddField = () => {
    const [fieldName, setFieldName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user && user.email === 'daviddoan01@gmail.com') {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        });
    }, []);

    const addFieldToAllDocuments = async () => {
        if (fieldName) {
            const collectionRef = collection(firestore, 'Deals');
            const snapshot = await getDocs(collectionRef);
            snapshot.forEach(async (docSnapshot) => {
                const docRef = doc(firestore, 'Deals', docSnapshot.id);
                await updateDoc(docRef, { [fieldName]: fieldValue });
            });
            setIsSubmitted(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        addFieldToAllDocuments();
    };

    if (!isAuthenticated) {
        return <p>You must be authenticated to view this form.</p>;
    }

    return (
        <div>
            <h1>Adding New Field to All Documents</h1>
            {isSubmitted ? (
                <p>Success! The field has been added to all documents.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Field Name"
                        value={fieldName}
                        onChange={(e) => setFieldName(e.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Field Value"
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                    />
                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default AddField;
