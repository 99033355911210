import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Disqus from "../../components/Disqus/Disqus";
import SingleDeal from "../../components/SingleDeal/SingleDeal";

const Deal = () => {

    return (
        <React.Fragment>
            <Header />
            <SingleDeal />
            <Footer />
        </React.Fragment>    
    );
}

export default Deal;
