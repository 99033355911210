import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { DiscussionEmbed } from 'disqus-react';

const SingleDeal = () => {
  const { itemId } = useParams();
  const [itemDetails, setItemDetails] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItemDetails = async () => {
      try {
        const itemDoc = doc(firestore, "Deals", itemId);
        const itemSnapshot = await getDoc(itemDoc);
        if (itemSnapshot.exists()) {
          setItemDetails(itemSnapshot.data());
        } else {
          setError("Item not found");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchItemDetails();
  }, [itemId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!itemDetails) {
    return <div>Loading...</div>;
  }

  const disqusConfig = {
    url: window.location.href,
    identifier: itemId,
    title: itemDetails.Title,
  };

  return (
    <div className="single-product-area" key="">
    <div className="zigzag-bottom"></div>
    <div className="container">
        <div className="row">

            <div className="col-md-12">
                <div className="product-content-right">
                    {/* <div className="product-breadcroumb">
                        <a href="/">Home</a>
                        <a href={'/product' + keyword}>{keyword.replaceAll('+',' ')}</a>
                    </div> */}
                    
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="product-images">
                                <div className="product-main-img">
                                    <img src={itemDetails.ImageURL} alt={itemDetails.Title} />
                                </div>
                                
                                <div className="product-gallery">
                                    
                                        <img src="" alt="" />
                                
                                                                                
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-6">
                            <div className="product-inner">
                                <h2 className="product-name">{itemDetails.Title}</h2>
                                <div className="product-inner-price">
                                    {/* {item.price.currency} */}
                                    <ins>${itemDetails.SalePrice}</ins> 
                                    {/* <del>${itemDetails.ListPrice}</del>  */}
                                    <span className='text-muted'>
                                        + Free Shipping
                                    </span>
                                    <span className='text-muted'>&nbsp;at eBay</span>
                                </div>    
                                
                                {/* <div className="quantity">
                                    <input type="number" size="4" className="input-text qty text" title="Qty" value="1" name="quantity" min="1" step="1" />
                                </div> */}
                                {/* <button className="add_to_cart_button" type="submit">SEE IT</button> */}
                                <a target="_blank" className="add_to_cart_button" href={itemDetails.AffiliateURL}>SEE IT</a>

                                {itemDetails.SubmittedBy ? <div> Thanks to community member <b>{itemDetails.SubmittedBy}</b> for finding this deal.</div> : ''}
                                
                                
                                {/* <div className="product-shipping">
                                    <p>
                                        Shipping: 
                                        { 
                                            (item.shippingOptions[0].shippingCost.value === "0.00") 
                                            ?  "Free" 
                                            : item.shippingOptions[0].shippingCost.currency + " " + item.shippingOptions[0].shippingCost.value
                                        }
                                    </p>
                                </div> */}

                                <div className="product-inner-category">
                                    {/* <p>Category: <a href="">Summer</a>. Tags: <a href="">awesome</a>, <a href="">best</a>, <a href="">sale</a>, <a href="">shoes</a>. </p> */}
                                </div> 
                                



                                
                            </div>
                        </div>
                    </div>
                    

                    {/* <TwitterShareButton
                            url={window.location.href}
                            title={singleDealData.name}
                        >
                        <TwitterIcon size={32} round={true}></TwitterIcon>
                        </TwitterShareButton>
                     */}
                    <DiscussionEmbed id="comment-box" shortname="pricelava" config={disqusConfig} />

                    
                    <div className="related-products-wrapper">
                        {/* <h2 className="related-products-title">Related Products</h2> */}
                        {/* <div className="related-products-carousel">
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-1.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Sony Smart TV - 2015</a></h2>

                                <div className="product-carousel-price">
                                    <ins>$700.00</ins> <del>$100.00</del>
                                </div> 
                            </div>
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-2.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Apple new mac book 2015 March :P</a></h2>
                                <div className="product-carousel-price">
                                    <ins>$899.00</ins> <del>$999.00</del>
                                </div> 
                            </div>
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-3.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Apple new i phone 6</a></h2>

                                <div className="product-carousel-price">
                                    <ins>$400.00</ins> <del>$425.00</del>
                                </div>                                 
                            </div>
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-4.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Sony playstation microsoft</a></h2>

                                <div className="product-carousel-price">
                                    <ins>$200.00</ins> <del>$225.00</del>
                                </div>                            
                            </div>
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-5.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Sony Smart Air Condtion</a></h2>

                                <div className="product-carousel-price">
                                    <ins>$1200.00</ins> <del>$1355.00</del>
                                </div>                                 
                            </div>
                            <div className="single-product">
                                <div className="product-f-image">
                                    <img src="../../assets/img/product-6.jpg" alt=""/>
                                    <div className="product-hover">
                                        <a href="" className="add-to-cart-link"><i className="fa fa-shopping-cart"></i> Add to cart</a>
                                        <a href="" className="view-details-link"><i className="fa fa-link"></i> See details</a>
                                    </div>
                                </div>

                                <h2><a href="">Samsung gallaxy note 4</a></h2>

                                <div className="product-carousel-price">
                                    <ins>$400.00</ins>
                                </div>                            
                            </div>                                    
                        </div> */}
                    </div>
                </div>                    
            </div>
        </div>
    </div>
    </div>

  );
};

export default SingleDeal;
