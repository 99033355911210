import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PostDealsAdmin from "../../components/PostDealsAdmin";

const PostDealsAdminPage = () => {

    return (
        <React.Fragment>
            <Header />
            <PostDealsAdmin />
            <Footer />
        </React.Fragment>    
    );
}

export default PostDealsAdminPage;
