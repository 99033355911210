import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function About() {

    return (
        <React.Fragment>
            <Header />
            <div className="container my-5">
            <div className="row">
                <div className="col-md-12 text-center p-4 bg-primary text-white rounded shadow-sm">
                    <h2 className="mb-0">Watch deals for the people by the people.</h2>
                </div>
            </div>
            </div>
            <div className="container my-5">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-center mb-4">PriceLava Terms of Service</h1>
                    <p className="text-muted text-center">Effective Date: 10/01/2024</p>
                    <p>These Terms of Service (the “Terms”) govern your use of the websites, software applications, and other online services (collectively, “Services”) provided by PriceLava, LLC (“PriceLava” or “we”). The Services include, but are not limited to, the website located at www.pricelava.com, the PriceLava mobile application, and the PriceLava browser extension, as well as any other online property that links to these Terms.</p>
                    <p>By using the Services, you are agreeing to these Terms, which incorporate our Acceptable Use Policy. These Terms are a binding agreement between you and PriceLava.</p>
                    <p>These Terms contain provisions that limit our liability to you and require you to resolve disputes individually, through final and binding arbitration. That means that you waive any right to have a judge or jury decide your case and that you cannot bring claims in a class action lawsuit (or any similar proceeding). Please see “Disclaimers”, “Limitation of Liability” and “Governing Law and Dispute Resolution” below for more information.</p>
                    
                    <h2>Changes</h2>
                    <p>We may make changes to these Terms at any time by posting them on the Services. Any changes will be effective at the time of posting.</p>
                    
                    <h2>Accounts</h2>
                    <p>In order to use certain Services, you will need to create an account. All the information you provide when you create your account must be true, accurate, current, and complete. You are responsible for all activity in your account, so please secure and protect your login information and password. PriceLava does not currently offer its Services in, or support user accounts from, Switzerland, the UK, or countries in the European Economic Area.</p>
                    
                    <h2>Offers</h2>
                    <p>You will find coupons, deals, advertisements, and other offers on the Services (“Offers”). Offers are for products and services provided by third parties (each, a “Seller”), and if you choose to accept an Offer, the transaction will be between you and the Seller.</p>
                    <p>PriceLava has relationships with certain Sellers, and we may get paid by brands or deals, including for promoted items. PriceLava is not a party to, or in any way responsible for, your interactions with a Seller, including when we have an affiliate relationship with the Seller. We are not responsible for fulfilling Offers or for the products and services in an Offer. </p>
                    <p>Before you purchase a product or service or otherwise accept an Offer, please read the entire description of the Offer, including the fine print and any additional information, terms, and conditions on the Seller’s website. You are responsible for understanding what you are buying and for following the Seller’s instructions. The terms and conditions of Offers, including rebate, refund, and cancellation policies, are governed by the Seller’s policies, not ours. Please contact the Seller directly for questions regarding the Offer or your transaction with the Seller.</p>
                    
                    <h2>Your Content</h2>
                    <p>Our Services may let you post content, including photos, images, comments, links, Offers, and other materials. Anything that you post or otherwise make available on or via the Services (including (1) content that you share to your social media accounts from the Services and (2) content that includes a PriceLava tag) or on our accounts on third-party platforms (e.g., Twitter, app stores) are referred to as “User Content.” User Content also includes your publicly visible profile, including your user name and other information about you and your account that is associated with your post.</p>
                    <p>When you post User Content, you represent and warrant that you have all rights necessary to do so, including but not limited to having sufficient intellectual property rights in the User Content, and that to the best of your knowledge, the User Content complies with all applicable laws.</p>
                    <p>As between you and PriceLava, aside from the limited license described below, you retain all rights in the User Content. You grant PriceLava, its affiliates, and its business partners (including Sellers and content syndicators) a perpetual, irrevocable, non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, publish, transmit, modify, move the location of, create derivative works of, perform, and distribute your User Content on the Services, third-party sites (e.g., under our accounts or our business partners’ accounts with social networking sites and apps or on our business partners’ websites and apps) and any other medium which now exists or may exist in the future. Nothing in these Terms shall restrict other legal rights PriceLava may have to User Content.</p>
                    <p>We are not obligated to review or monitor User Content, but we reserve the right to remove or modify User Content for any reason, including User Content that we believe violates these Terms or our policies.</p>
                    <p>We do not guarantee how quickly your User Content will appear on the Services or whether, how, and where it will appear. We reserve the exclusive right to describe, categorize, and place Offers in our sole discretion.</p>
                    <p>We value hearing from you and are always interested in learning about ways we can improve the Services. If you choose to submit comments, ideas, or feedback, whether via the Services or any third-party platform (e.g., Twitter, the Apple App Store, or Google Play Store), you agree that we may use them without restriction and without any compensation, attribution, or accounting to you.</p>
                    
                    <h2>Community Standards</h2>
                    <p>In addition to your other responsibilities under these Terms, including our Acceptable Use Policy, you must comply with the following:</p>
                    <ul>
                        <li>Do not use the Services, including our site, app, or extension, if you are under age 16.</li>
                        <li>You may only open one account.</li>
                        <li>You must abide by all applicable laws, refrain from violating any third-party rights, and comply with any applicable third-party terms and agreements in connection with your use of the Services.</li>
                        <li>You must comply with all policies posted on the Services.</li>
                        <li>Do not transfer your PriceLava account to another person without our written consent.</li>
                        <li>Accounts that have been terminated for failing to comply with these Terms may not be reopened under another name.</li>
                        <li>You must provide accurate and honest information to us at all times, including in your User Content.</li>
                        <li>Your Offers may only include products or services that fall within the categories shown on the Services.</li>
                        <li>Do not reproduce, distribute, modify, prepare derivative works of, translate, reverse engineer, reverse compile, or disassemble the Services or any portion of the Services.</li>
                        <li>Do not derive the source code of the Services for any reason.</li>
                        <li>Do not rent, sell, or sublicense any of the Services.</li>
                        <li>Do not damage, interfere with, disrupt, or unreasonably overload the Services.</li>
                        <li>Do not post deals where you are the seller or manufacturer of the product or service offered in the deal. Please feel free to reach out to our Business Development team to learn more about how to surface great deals to our users.</li>
                        <li>Do not offer or accept compensation for voting on a deal.</li>
                    </ul>
                    
                    <h2>Our Content and Proprietary Rights</h2>
                    <p>Do not copy, repurpose, or distribute any content found on the Services, including other users’ User Content and Offers, for any purpose, without our express written permission. For example, do not copy or display Offers via any other website or app. Unless we have given you express written permission, you specifically agree not to access (or attempt to access) any of the Services via any automated means (including scripts, web crawlers, or other bots) and to comply with the instructions set out in any robots.txt file present on the Services. Except as set forth in these Terms, you agree not to copy, distribute, modify, prepare derivative works of, translate, reverse engineer, reverse compile, or disassemble the Services or any portion of the Services.</p>
                </div>
            </div>
        </div>

          

            <Footer />
        </React.Fragment>
      
    );
}

export default About;
