import React from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import SingleItemUpdate from "../../../components/SingleItemUpdate/index";

const AdminUpdate = () => {
    return (
        <React.Fragment>
            <Header />
            <SingleItemUpdate />
            <Footer />
        </React.Fragment>
    );
}

export default AdminUpdate;
