import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PostDeals from "../../components/PostDeals";

const PostDealsPage = () => {

    return (
        <React.Fragment>
            <Header />
            <PostDeals />
            <Footer />
        </React.Fragment>    
    );
}

export default PostDealsPage;
