import styled from 'styled-components';

export const DivHeader = styled.div`
    position: relative;
    top: 0px;
    right: 0px;
    left: 0px;
    transition: all 250ms ease-in-out 0s;
    z-index: 99;
`;

export const UpdateMainDiv = styled.div`
    display: flex;
    flex-direction: column; 

    label {
        margin-top: 20px; 
    }
`;