import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { auth, signInWithGoogle } from '../../firebase'; // Adjust the import path as needed
import { signOut } from "firebase/auth";
import Button from '../Button';
import { DivSearchBar, StyledHeader, StyledNav, StyledLink, PriceLavaSlogan, 
        LogoContainer, LogoImgContainer, VersionText } from '../Header/styledComponents';
import Logo from '../../assets/img/logo.png';
import PriceLavaLogo from '../../assets/img/pricelavalogo.png';
import PriceLavaSloganImg from '../../assets/img/1.png';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = () => {
    signInWithGoogle()
      .then((result) => {
        setUser(result.user);
      })
      .catch((error) => {
        console.error("Error during sign-in:", error);
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error("Error during sign-out:", error);
      });
  };

  return (
    <React.Fragment>
      <div className="header-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="user-menu">
                <ul>
                  {user ? (
                    <>
                      <li>Welcome, {user.displayName}</li>
                      <li><a href="#" onClick={handleLogout}>Logout</a></li>
                    </>
                  ) : (
                    <li><a href="#" onClick={handleLogin}>Login</a></li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-branding-area">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <LogoContainer className="logo">
                <LogoImgContainer>
                  <a href="/"><img width="160" src={PriceLavaLogo} alt="PriceLava Logo" /></a>
                </LogoImgContainer>
                <PriceLavaSlogan>
                  <img src={PriceLavaSloganImg} alt="PriceLava.com watch deals for the people by the people."></img>
                </PriceLavaSlogan>
              </LogoContainer>
              <VersionText className="fs-6 text-black-50">Beta v1.0.0</VersionText>
            </div>
          </div>
        </div>
      </div>

      <StyledHeader>
        <div className="container">
          <StyledNav>
            <StyledLink href="/">Home</StyledLink>
            <StyledLink href="/postdealspage">Post A Deal</StyledLink>
            <StyledLink href="/about">About Us</StyledLink>
          </StyledNav>
        </div>
      </StyledHeader>
    </React.Fragment>
  );
};

export default Header;
