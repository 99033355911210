import React from "react";
//import { DivHeader, DivSubHeader } from '../Header/styledComponents';

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <React.Fragment>
               <div className="footer-top-area">
                    <div className="zigzag-bottom"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-about-us">
                                    <h2><span>PriceLava</span></h2>
                                    <p>
                                        PriceLava - utilizing its expertise in technology and data driven performance to help the online
                                        community find the lowest prices for watches and other products. Watch deals for the people by the people. PriceLava.com 
                                    </p>

                                    {/* Social media <div className="footer-social">
                                        <a href="#" target="_blank"><i className="fa fa-facebook"></i></a>
                                        <a href="#" target="_blank"><i className="fa fa-twitter"></i></a>
                                        <a href="#" target="_blank"><i className="fa fa-youtube"></i></a>
                                        <a href="#" target="_blank"><i className="fa fa-linkedin"></i></a>
                                    </div> */}
                                </div>
                            </div>
                            
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-menu">
                                    <h2 className="footer-wid-title">Follow Us</h2>
                                    <ul>
                                        {/* <li><a href="#">My account</a></li>
                                        <li><a href="#">Order history</a></li>
                                        <li><a href="#">Wishlist</a></li>
                                        <li><a href="#">Vendor contact</a></li> */}
                                        <li><a href="/">Front page</a></li>
                                        <li><a href="/">Coming soon</a></li>
                                    </ul>                        
                                </div>
                            </div>
                            
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-menu">
                                    <h2 className="footer-wid-title">Legal</h2>
                                    <ul>
                                        <li><a href="/termsandconditions">Terms of Service</a></li>
                                        {/* <li><a href="/">Privacy Policy</a></li> */}
                                        {/* <li><a href="/product/4k+tv">4k TV</a></li>
                                        <li><a href="/product/laptop">Laptop</a></li>
                                        <li><a href="/product/xbox+series+x">Games</a></li> */}
                                    </ul>                        
                                </div>
                            </div>
                            
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-newsletter">
                                    <h2 className="footer-wid-title">Newsletter</h2>
                                    {/* <p>Sign up to our newsletter and get exclusive deals you wont find anywhere else straight to your inbox!</p> */}
                                    <p>Sign up to our newsletter - coming soon.</p>

                                    {/* <div className="newsletter-form">
                                            <input type="email" placeholder="Type your email" />
                                            <input type="submit" value="Subscribe" />
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                {/* <!-- End footer top area --> */}
                
                <div className="footer-bottom-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="copyright">
                                    <p>&copy; {year} PriceLava. All Rights Reserved.</p>
                                </div>
                            </div>
                            
                            {/* <div className="col-md-4">
                                <div className="footer-card-icon">
                                    <i className="fa fa-cc-discover"></i>
                                    <i className="fa fa-cc-mastercard"></i>
                                    <i className="fa fa-cc-paypal"></i>
                                    <i className="fa fa-cc-visa"></i>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div> 
                {/* <!-- End footer bottom area --> */}
           
           
        </React.Fragment>
      
    )
}


export default Footer
