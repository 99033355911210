import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

function TermsAndConditions() {

    return (
        <React.Fragment>
            <Header />
            <div className="container my-5">
            <div className="row">
                <div className="col-md-12 text-center p-4 bg-primary text-white rounded shadow-sm">
                    <h2 className="mb-0">Watch deals for the people by the people.</h2>
                </div>
            </div>
            </div>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-12 text-center p-4 bg-light rounded shadow-sm">
                        <h2 className="mb-3">Price Lava</h2>
                        <p className="lead">
                            Utilizing its expertise in technology and data-driven performance to help the online 
                            community find the lowest prices for watches and other products. 
                        </p>
                    </div>
                </div>
            </div>

          

            <Footer />
        </React.Fragment>
      
    );
}

export default TermsAndConditions;
