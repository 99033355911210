import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { collection, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firestore } from '../../firebase';
import { Modal, Button, Form } from 'react-bootstrap';
import { UrlContainer } from './styledComponents';

const PostDealsAdmin = () => {
  const [itemId, setItemId] = useState('');
  const [itemDetails, setItemDetails] = useState(null);
  const [deals, setDeals] = useState([]);
  const [editingDeal, setEditingDeal] = useState(null);
  const [updatedData, setUpdatedData] = useState({ AffiliateURL: '', description: '', submittedBy: '', submittedDate: '', imageUrl: '', salePrice: '', title: '' });
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.email === 'daviddoan01@gmail.com') {
          setUser(user);
          fetchDeals();
        } else {
          setError('You do not have permission to view this page.');
        }
      } else {
        setError('You are not authenticated.');
      }
    });
  }, []);

  const fetchDeals = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'PostDeals'));
      const dealsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDeals(dealsData);
    } catch (error) {
      setError('Failed to fetch deals.');
    }
  };

  const fetchItemDetails = async () => {
    try {
      const response = await axios.get(`https://ebay-node.vercel.app/api/singleitem/item/v1|${itemId}|0`);
      setItemDetails(response.data);
      setError(null);
      console.log(response.data);
      // Prefill the form with fetched item details
      setUpdatedData((prevData) => ({
        ...prevData,
        AffiliateURL: response.data.itemAffiliateWebUrl || '',
        //description: response.data.Description || '',
        imageUrl: response.data?.image?.imageUrl || '',
        salePrice: response.data?.price?.value || '',
        title: response.data?.title || ''
      }));
    } catch (error) {
      setError('An error occurred while fetching the item data');
      setItemDetails(null);
    }
  };

  const handleEdit = (deal) => {
    setEditingDeal(deal.id);
    setUpdatedData({
      AffiliateURL: deal.ebayUrl || '',
      description: deal.description || '',
      submittedBy: deal.submittedBy || '',
      submittedDate: deal.submittedDate || '',
      imageUrl: deal.imageUrl || '',
      salePrice: deal.salePrice || '',
      title: deal.title || '',
      itemId: ''
    });
    setShowModal(true);
  };

  const handleUpdate = async (id) => {
    try {
      const dealDoc = doc(firestore, 'PostDeals', id);
      await updateDoc(dealDoc, updatedData);
      setEditingDeal(null);
      setUpdatedData({ AffiliateURL: '', description: '', submittedBy: '', submittedDate: '', imageUrl: '', salePrice: '', title: '', itemId: '' });
      fetchDeals();
      setShowModal(false);
    } catch (error) {
      setError('Failed to update deal.');
    }
  };

  const handleApprove = (deal) => {
    setEditingDeal(deal.id);
    setUpdatedData({
      AffiliateURL: deal.ebayUrl || '',
      description: deal.description || '',
      submittedBy: deal.submittedBy || '',
      submittedDate: deal.submittedDate || '',
      imageUrl: deal.imageUrl || '',
      salePrice: deal.salePrice || '',
      title: deal.title || '',
      itemId: ''
    });
    setShowModal(true);
  };

  const handleSubmit = async () => {
    try {
      const newDeal = {
        AffiliateURL: updatedData.AffiliateURL,
        DateAdded: new Date().toISOString(),
        DateUpdated: new Date().toISOString(),
        Description: updatedData.description,
        ImageURL: updatedData.imageUrl,
        InStock: 'IN_STOCK',
        ListPrice: '',
        SalePrice: updatedData.salePrice,
        Title: updatedData.title
      };
      await addDoc(collection(firestore, 'Deals'), newDeal);
      await handleUpdate(editingDeal);
    } catch (error) {
      console.log(error);
      setError('Failed to approve deal.');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  if (error) {
    return <div className="container my-5"><h1 className="text-center mb-4">{error}</h1></div>;
  }

  if (!user) {
    return <div className="container my-5"><h1 className="text-center mb-4">Loading...</h1></div>;
  }

  return (
    <div className="container my-5">
      <h1 className="text-center mb-4">Posted Deals Admin Page</h1>
      <div className="list-group">
        {deals.map((deal) => (
          <div key={deal.id} className="list-group-item">
            <div className="mb-2 text-wrap">
              <UrlContainer>
                <strong>eBay URL:</strong>
                <a href={deal.ebayUrl} target='_blank'>{deal.ebayUrl}</a>
              </UrlContainer>
            </div>
            <div className="mb-2">
              <strong>Description:</strong> {deal.description}
            </div>
            <div className="mb-2">
              <strong>Is Approved:</strong> {deal.isApproved}
            </div>
            <div className="mb-2">
              <strong>Submitted By:</strong> {deal.submittedBy}
            </div>
            <div className="mb-2">
              <strong>Submitted Date:</strong> {deal.submittedDate}
            </div>
            <button className="btn btn-primary me-2" onClick={() => handleEdit(deal)}>Edit</button>
            <button className="btn btn-success" onClick={() => handleApprove(deal)}>Approve</button>
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Approve Deal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              value={itemId}
              onChange={(e) => setItemId(e.target.value)}
              placeholder="Enter Item ID"
            />
          </div>
          <button className="btn btn-primary mb-3" onClick={fetchItemDetails}>Fetch Item Details</button>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Affiliate URL</Form.Label>
              <Form.Control
                type="text"
                name="AffiliateURL"
                value={updatedData.AffiliateURL}
                onChange={handleChange}
                placeholder="Update Affiliate URL"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={updatedData.description}
                onChange={handleChange}
                placeholder="Update Description"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Submitted By</Form.Label>
              <Form.Control
                type="text"
                name="submittedBy"
                value={updatedData.submittedBy}
                onChange={handleChange}
                placeholder="Update Submitted By"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Submitted Date</Form.Label>
              <Form.Control
                type="text"
                name="submittedDate"
                value={updatedData.submittedDate}
                onChange={handleChange}
                placeholder="Update Submitted Date"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                name="imageUrl"
                value={updatedData.imageUrl}
                onChange={handleChange}
                placeholder="Update Image URL"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Sale Price</Form.Label>
              <Form.Control
                type="text"
                name="salePrice"
                value={updatedData.salePrice}
                onChange={handleChange}
                placeholder="Update Sale Price"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={updatedData.title}
                onChange={handleChange}
                placeholder="Update Title"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PostDealsAdmin;
